import React, { useEffect, useState } from "react";
import Axios from "axios";

import lyp_bg from "../../assets/images/lyp_bg.png";

const LypSponsors = () => {
    const [sponsorRows, setSponsorRows] = useState([]);

    useEffect(() => {
        Axios.get("https://sp-admin.moodi.org/getSponsors?category=lyp")
            .then((res) => {
                setSponsorRows(
                    [...Array(Math.ceil(res.data.length / 4))].map((row, idx) =>
                        res.data.slice(idx * 4, idx * 4 + 4)
                    )
                );
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <div className="sp_cat_container">
            <img src={lyp_bg} className="sp_cat_bg" alt="" />
            <p className="sp_cat_title">Live Your Passion Partners</p>
            {sponsorRows.map((row, idx) => {
                return (
                    <div className="sponsor_row" key={idx}>
                        {row.map((element, idx) => {
                            return (
                                <a
                                    href={element.hyperlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="sponsor_card"
                                    key={idx}
                                >
                                    <div className="sponsor_img">
                                        <img
                                            src={`https://sp-admin.moodi.org/get_image?img=${element.image}`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sponsor_text">
                                        <div className="sponsor_name">
                                            {element.name}
                                        </div>
                                        <div className="sponsor_tag">
                                            {element.tag}
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default LypSponsors;
