import React, { useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import mi_logo_nav from "../assets/images/mi_logo_nav.png";
import Hamburger from "hamburger-react";

import {
    FaInstagram,
    FaLinkedin,
    FaSquareFacebook,
    FaWhatsapp,
    FaXTwitter,
    FaYoutube,
} from "react-icons/fa6";

const Navbar = () => {
    const location = useLocation();
    const navRef = useRef(null);
    return (
        <>
            <div className="navbar_container">
                {location.pathname === "/" ? (
                    <></>
                ) : (
                    <NavLink
                        to={"/"}
                        className={({ isActive }) =>
                            isActive ? "active" : "inactive"
                        }
                    >
                        <img src={mi_logo_nav} className="mi_logo_nav" alt="" />
                    </NavLink>
                )}
                <NavLink
                    to={"/events"}
                    className={({ isActive }) =>
                        isActive ? "active no-mob" : "inactive no-mob"
                    }
                >
                    Events
                </NavLink>
                <a className="no-mob" href="https://my.moodi.org/competitions">
                    Competitions
                </a>
                <a className="no-mob" href="https://my.moodi.org/accommodation">
                    Accommodation
                </a>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? "active no-mob" : "inactive no-mob"
                    }
                    to={"/schedule"}
                >
                    Schedule
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? "active no-mob" : "inactive no-mob"
                    }
                    to={"/sponsors"}
                >
                    Sponsors
                </NavLink>
                <NavLink
                    to={"/contact"}
                    className={({ isActive }) =>
                        isActive ? "active no-mob" : "inactive no-mob"
                    }
                >
                    Contact Us
                </NavLink>
                {localStorage.getItem("miauthtoken") ? (
                    <NavLink
                        to={"/profile"}
                        className={({ isActive }) =>
                            isActive ? "active no-mob" : "inactive no-mob"
                        }
                    >
                        Concert Pass
                    </NavLink>
                ) : (
                    <NavLink
                        to={"/register?redirect=profile"}
                        className="reg_btn no-mob"
                    >
                        Register
                    </NavLink>
                )}
            </div>
            <div className="hamburger">
                <Hamburger
                    color="#ffc700"
                    onToggle={(toggle) => {
                        if (toggle) {
                            navRef.current.classList.add("active");
                        } else {
                            navRef.current.classList.remove("active");
                        }
                    }}
                />
            </div>
            <div className="mob_nav" ref={navRef}>
                <NavLink
                    to={"/"}
                    className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                    }
                >
                    Home
                </NavLink>
                <NavLink
                    to={"/events"}
                    className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                    }
                >
                    Events
                </NavLink>
                <a href="https://my.moodi.org/competitions">Competitions</a>
                <a href="https://my.moodi.org/accommodation">Accommodation</a>
                <NavLink to={"/schedule"}>Schedule</NavLink>
                <NavLink to={"/sponsors"}>Sponsors</NavLink>
                <NavLink
                    to={"/contact"}
                    className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                    }
                >
                    Contact Us
                </NavLink>
                {localStorage.getItem("miauthtoken") ? (
                    <NavLink
                        to={"/profile"}
                        className={({ isActive }) =>
                            isActive ? "active" : "inactive"
                        }
                    >
                        Concert Pass
                    </NavLink>
                ) : (
                    <NavLink
                        to={"/register?redirect=profile"}
                        className="reg_btn"
                    >
                        Register
                    </NavLink>
                )}
                <div className="navbar_icons">
                    <a
                        href="https://www.youtube.com/@iitbmoodindigo"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaYoutube
                            color="white"
                            size={window.innerWidth > 770 ? 50 : 20}
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/iitbombay.moodi/?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaInstagram
                            color="white"
                            size={window.innerWidth > 770 ? 50 : 20}
                        />
                    </a>
                    <a
                        href="https://in.linkedin.com/company/mood-indigo"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaLinkedin
                            color="white"
                            size={window.innerWidth > 770 ? 50 : 20}
                        />
                    </a>
                    <a
                        href="https://twitter.com/iitb_moodi?lang=en"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaXTwitter
                            color="white"
                            size={window.innerWidth > 770 ? 50 : 20}
                        />
                    </a>
                    <a
                        href="https://www.facebook.com/iitbombay.moodi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaSquareFacebook
                            color="white"
                            size={window.innerWidth > 770 ? 50 : 20}
                        />
                    </a>
                </div>
            </div>
        </>
    );
};

export default Navbar;
