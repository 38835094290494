import React from "react";
import obj10 from "../../assets/images/obj-10.svg";
import obj11 from "../../assets/images/obj-11.svg";
import obj46 from "../../assets/images/obj-46.svg";
import obj59 from "../../assets/images/obj-59.svg";
import MILogo from "../../assets/images/mi-logo.svg";
import book1 from "../../assets/images/book-1.svg";

import { useNavigate } from "react-router-dom";

const RegistrationTemplate = () => {
    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate("/");
    };

    return (
        <div className="reg-top">
            <div className="ccp-logo-reg" onClick={onLogoClick}>
                <div className="mi-logo-reg">
                    <img src={MILogo} style={{ zIndex: 10 }} alt="" />
                    <div
                        style={
                            window.innerWidth > 770
                                ? { width: "19vw", height: "7vh" }
                                : { width: "55vw", height: "7vh" }
                        }
                        className="bg-blue mi-logo-text center"
                    >
                        <p>MOOD INDIGO</p>
                    </div>
                </div>
            </div>
            <div className="elements-reg">
                <img src={obj46} className="obj-46-reg tab" alt="" />
                <img src={book1} className="book-1-reg no-mob tab" alt="" />
                <img src={obj11} className="obj-11-reg no-mob tab" alt="" />
                <img src={obj59} className="obj-59-reg no-mob tab" alt="" />
                <img src={obj10} className="obj-10-reg no-mob tab" alt="" />
            </div>
        </div>
    );
};

export default RegistrationTemplate;
