import React from "react";
import "./Sponsors.css";
import sponsor_grad from "../../assets/images/sponsor_grad.png";
import sponsor_ill from "../../assets/images/sponsor_ill.png";
import Navbar from "../Navbar";
import Footer from "../Footer";
import whisper from "../../assets/images/sponsors/whisper.png";
import spinny from "../../assets/images/sponsors/spinny.png";
import bob from "../../assets/images/sponsors/bob.png";
import sbi from "../../assets/images/sponsors/sbi.png";
import schmitten from "../../assets/images/sponsors/schmitten.png";
import zudio from "../../assets/images/sponsors/zudio.png";
import MarkiSponsors from "./MarkiSponsors";
import MediaSponsors from "./MediaSponsors";
import LypSponsors from "./LypSponsors";
import FnBSponsors from "./FnBSponsors";

const SponsorHome = () => {
    return (
        <div className="sponsor_page">
            <Navbar />
            <div className="sponsor_home">
                <img src={sponsor_grad} className="sponsor_grad" alt="" />
                <img src={sponsor_ill} className="sponsor_ill" alt="" />
                <div className="sponsor_title">Sponsors</div>
                <p className="sponsor_info">
                    Over the past years Mood Indigo has had the privilege to
                    have hosted a number of sponsors which provided a very
                    entertaining experience to our visitors as well as the
                    brand. The crowd engagement, media exposure, sampling and
                    brand building opportunities offered at Mood Indigo are
                    unparalleled.
                </p>
                {/* <div className="sponsor_contact">
                    <p>Contact Us:</p>
                    <p>
                        <strong>Akansha Padmane: </strong>
                        <a href="tel:+918983280146">+91 89832 80146</a>
                    </p>
                    <p>
                        <strong>Arnav Pandit: </strong>
                        <a href="tel:+918905093878">+91 89050 93878</a>
                    </p>
                    <p>
                        <strong>Likith Reddy: </strong>
                        <a href="tel:+919731455444">+91 97314 55444</a>
                    </p>
                </div> */}
            </div>
            <div className="title_sponsor_container">
                <a href="https://whisper.co.in/" className="ts_card">
                    <div className="ts_image">
                        <img src={whisper} alt="" />
                    </div>
                    <div className="ts_desc">
                        <p className="sponsor_name">Whisper</p>
                        <p className="sponsor_tag">Title Sponsor</p>
                    </div>
                </a>
            </div>
            <div className="ats_container">
                <a href="https://www.zudio.com/" className="ats_card">
                    <div className="ats_image">
                        <img src={zudio} alt="" />
                    </div>
                    <div className="ats_desc">
                        <p className="sponsor_name">Zudio</p>
                        <p className="sponsor_tag">Styled By Partner</p>
                    </div>
                </a>
                <a
                    href="https://schmitten.co/index_1.html"
                    className="ats_card"
                >
                    <div className="ats_image">
                        <img src={schmitten} alt="" />
                    </div>
                    <div className="ats_desc">
                        <p className="sponsor_name">Schmitten</p>
                        <p className="sponsor_tag">Associate Title Sponsor</p>
                    </div>
                </a>
            </div>
            <div className="cts_container">
                <a href="https://www.bankofbaroda.in/" className="ats_card">
                    <div className="ats_image">
                        <img src={bob} alt="" />
                    </div>
                    <div className="ats_desc">
                        <p className="sponsor_name">Bank of Baroda</p>
                        <p className="sponsor_tag">Banking Partner</p>
                    </div>
                </a>
                <a href="https://www.spinny.com/" className="ats_card">
                    <div className="ats_image">
                        <img src={spinny} alt="" />
                    </div>
                    <div className="ats_desc">
                        <p className="sponsor_name">Spinny</p>
                        <p className="sponsor_tag">Driven By Partner</p>
                    </div>
                </a>
                <a href="https://www.spinny.com/" className="ats_card">
                    <div className="ats_image">
                        <img src={sbi} alt="" />
                    </div>
                    <div className="ats_desc">
                        <p className="sponsor_name">State Bank of India</p>
                        <p className="sponsor_tag">Co-Title Sponsor</p>
                    </div>
                </a>
            </div>

            <MarkiSponsors />
            <MediaSponsors />
            <LypSponsors />
            <FnBSponsors />
            <Footer />
        </div>
    );
};

export default SponsorHome;
