import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import rightArrow from "../../assets/images/right-arrow.svg";
import leftArrow from "../../assets/images/left-arrow.svg";
import { useNavigate } from "react-router-dom";

const EmailLogin = ({ user, setUser, page, setPage }) => {
    const otpRef = useRef(null);
    const continueBtnRef = useRef(null);
    const nextBtnRef = useRef(null);
    let navigate = useNavigate();

    const [otp, setOtp] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const onContinueClick = (e) => {
        e.preventDefault();
        if (user.otp != "") {
            if (user.otp == otp) {
                Axios.post(
                    "https://ashish.moodi.org/users/getUser",
                    {
                        email: user.email,
                        password: user.password,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then((res) => {
                        if (res.data.exists) {
                            if (res.data.msg) {
                                alert("Invalid Credentials! Please try again.");
                            }
                            if (res.data.authtoken) {
                                localStorage.setItem(
                                    "miauthtoken",
                                    res.data.authtoken
                                );
                                navigate("/profile");
                            }
                        } else {
                            let nextBtn = nextBtnRef.current;
                            nextBtn.click();
                        }
                    })
                    .catch((err) => console.error(err));
                // let nextBtn = nextBtnRef.current;
                // nextBtn.click();
            } else {
                alert("Please enter the correct OTP!");
            }
        } else {
            if (user.email != "" && user.password.length >= 8) {
                if (isValidEmail(user.email)) {
                    Axios.post(
                        "https://ashish.moodi.org/email/sendOtp",
                        {
                            email: user.email,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    )
                        .then((res) => {
                            setOtp(res.data.otp);
                            alert(
                                "An email with an OTP has been sent to your email id. Please check the spam/promotions folder as well."
                            );
                            let otpField = otpRef.current;
                            otpField.classList.remove("no-disp");
                            let cntBtn = continueBtnRef.current;
                            cntBtn.innerHTML = "Submit";
                        })
                        .catch((err) => {
                            alert(
                                "Sorry! Your request cannot be processed at the moment. Please try again!"
                            );
                            console.error(err);
                        });
                } else {
                    alert("Please enter a valid email!");
                }
            } else {
                alert("Email and Password cannot be empty");
            }
        }
    };

    useEffect(() => {
        if (user.googleId !== "") {
            let nextBtn = nextBtnRef.current;
            nextBtn.click();
        }
    });
    return (
        <form className="step">
            <input
                type="email"
                name="email"
                id="email"
                onChange={onChange}
                placeholder="Email*"
                autoComplete="email"
                value={user.email}
                required
            />
            <input
                type="password"
                name="password"
                id="password"
                minLength={8}
                placeholder="Password*"
                onChange={onChange}
                autoComplete="new-password"
                value={user.password}
            />
            <div className="form-group form-group-otp">
                <input
                    type="text"
                    name="otp"
                    id="otp"
                    className="no-disp"
                    placeholder="OTP*"
                    onChange={onChange}
                    value={user.otp}
                    ref={otpRef}
                    maxLength={6}
                    required
                />
                <button
                    ref={continueBtnRef}
                    className="btn continue-btn"
                    onClick={onContinueClick}
                    type="submit"
                >
                    Get OTP
                </button>
            </div>
            <div className="form-footer">
                <button
                    type="submit"
                    className="btn back-btn"
                    onClick={() => {
                        setPage(page - 1);
                    }}
                >
                    <img src={leftArrow} alt="" />
                    Back
                </button>
                <button
                    ref={nextBtnRef}
                    type="submit"
                    onClick={handleSubmit}
                    className="btn next-btn"
                    style={{ display: "none" }}
                >
                    Next
                    <img src={rightArrow} alt="" />
                </button>
            </div>
        </form>
    );
};

export default EmailLogin;
