import React from "react";
import Navbar from "../Navbar";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Footer from "../Footer";

const Home = () => {
    return (
        <div className="container">
            <Navbar />
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <Page5 />
            {/* <Page6 /> */}
            <Footer />
        </div>
    );
};

export default Home;
