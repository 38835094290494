import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import pg3_gradient from "../assets/images/pg3_gradient.png";
import day3passes from "../assets/constants/concert_d3";
import duck from "../assets/images/duck.gif";

const Profile = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(
        localStorage.getItem("miauthtoken")
            ? jwtDecode(localStorage.getItem("miauthtoken")).user
            : null
    );

    useEffect(() => {
        if (localStorage.getItem("miauthtoken")) {
        } else {
            navigate("/register?redirect=profile");
        }
    }, []);
    return (
        <div className="profile_page">
            <img src={pg3_gradient} className="profile_gradient" alt="" />
            <Navbar />
            <div className="qrSection">
                <QRCode value={user.userId} />
            </div>
            {day3passes.find((element) => element == user.userId) ? (
                <>
                    <p className="userName">{user.userName}</p>
                    <p className="mi_no">{user.MI_NO}</p>
                    <p className="success">
                        Your Entry into Gymkhana is confirmed.
                    </p>

                    <img src={duck} className="duck_img_concert" alt="" />
                </>
            ) : (
                <>
                    <p className="userName">
                        Visit Hospitality Desk at SAC to activate your Concert
                        Pass.
                    </p>
                    <p className="mi_no">
                        You will get your Concert Pass here if activated
                        correctly around 4.30pm
                    </p>
                    <p className="userName">{user.userName}</p>
                    <p className="mi_no">{user.MI_NO}</p>
                </>
            )}
        </div>
    );
};

export default Profile;
