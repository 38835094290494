import React from "react";

import {
    FaInstagram,
    FaLinkedin,
    FaSquareFacebook,
    FaWhatsapp,
    FaXTwitter,
    FaYoutube,
} from "react-icons/fa6";

const Footer = () => {
    return (
        <div className="footer">
            <div className="icon-container">
                <a
                    href="https://www.youtube.com/@iitbmoodindigo"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaYoutube
                        color="white"
                        size={window.innerWidth > 770 ? 50 : 20}
                    />
                </a>
                <a
                    href="https://www.instagram.com/iitbombay.moodi/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaInstagram
                        color="white"
                        size={window.innerWidth > 770 ? 50 : 20}
                    />
                </a>
                <a
                    href="https://in.linkedin.com/company/mood-indigo"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaLinkedin
                        color="white"
                        size={window.innerWidth > 770 ? 50 : 20}
                    />
                </a>
                <a
                    href="https://twitter.com/iitb_moodi?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaXTwitter
                        color="white"
                        size={window.innerWidth > 770 ? 50 : 20}
                    />
                </a>
                <a
                    href="https://www.facebook.com/iitbombay.moodi/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaSquareFacebook
                        color="white"
                        size={window.innerWidth > 770 ? 50 : 20}
                    />
                </a>
            </div>
            <div className="address">
                <p>
                    Mood Indigo Office, Student Activity Center (SAC), IIT
                    Bombay, Powai, Mumbai, Maharashtra - 400076
                </p>
            </div>
        </div>
    );
};

export default Footer;
