import React from "react";
import rightArrow from "../../assets/images/right-arrow.svg";
import Axios from "axios";

function PersonalInfo({ user, setUser, page, setPage }) {
    const handleSubmit = (e) => {
        let userOtp = "";
        e.preventDefault();
        if (
            user.name != "" &&
            user.dob != "" &&
            user.phone != "" &&
            user.gender != ""
        ) {
            // if (user.phone.length == 10) {
            //     Axios.post("https://sp-admin.moodi.org/verify_phone", {
            //         phone: user.phone,
            //     }).then((res) => {
            //         if (res.data.status === "done") {
            //             userOtp = window.prompt(
            //                 "Enter OTP sent to you on Whatsapp!"
            //             );
            //             if (userOtp == res.data.otp) {
            //                 window.alert("OTP Verified Successfully");
            //                 setPage(page + 1);
            //             } else {
            //                 userOtp = window.prompt(
            //                     "Invalid OTP, Please try again"
            //                 );
            //                 if (userOtp == res.data.otp) {
            //                     window.alert("OTP Verified Successfully");
            //                 }
            //             }
            //         } else {
            //             window.alert(
            //                 "Internal Server Error! Please try again later!"
            //             );
            //         }
            //     });
            // }
            setPage(page + 1);
        } else {
            alert("* marked fields cannot be left empty");
        }
    };
    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    return (
        <form className="step reg-step-1">
            <input
                type="text"
                id="name"
                name="name"
                placeholder="Full Name*"
                onChange={onChange}
                value={user.name}
                autoComplete="name"
                minLength={3}
                required
            />
            <input
                type="text"
                id="dob"
                name="dob"
                placeholder="Date of Birth*"
                onFocus={(e) => (e.target.type = "date")}
                onChange={onChange}
                value={user.dob}
                required
            />
            <div className="form-group">
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Ph. Number*"
                    onChange={onChange}
                    value={user.phone}
                    maxLength={10}
                    required
                    autoComplete="tel-national"
                />
                <select
                    name="gender"
                    id="gender"
                    onChange={onChange}
                    value={user.gender}
                    required
                    autoComplete="sex"
                >
                    <option value="">Gender*</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div className="form-footer">
                <div
                    style={{ backgroundColor: "#FABCD5" }}
                    className="btn buffer-btn"
                ></div>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn next-btn"
                >
                    Next
                    <img src={rightArrow} alt="" />
                </button>
            </div>
        </form>
    );
}

export default PersonalInfo;
