import React from "react";

import schedule_btn from "../../assets/images/schedule_btn.png";
import ps from "../../assets/images/ps_blog.png";
import ss from "../../assets/images/ss.png";
import pt from "../../assets/images/pt.png";
import si from "../../assets/images/si.png";
import ap from "../../assets/images/ap.png";
import au from "../../assets/images/au.png";
import vg from "../../assets/images/vg.png";
import cs from "../../assets/images/cs.png";
import js from "../../assets/images/js.png";
import ab from "../../assets/images/ab.png";
import ns from "../../assets/images/ns.png";
import raahgir from "../../assets/images/rahgir.png";
import gradient from "../../assets/images/gradient.png";
import { useNavigate } from "react-router-dom";

const Page2 = () => {
    const navigate = useNavigate();
    return (
        <div className="page_2">
            <h1>HEADLINING EVENTS</h1>
            <img src={gradient} className="gradient" alt="" />
            <div className="event_cards_container">
                <div className="row">
                    <div className="event_card">
                        <div className="poster">
                            <img src={js} alt="" />
                        </div>
                        <div className="details">
                            <div className="date">18th December</div>
                            <div className="time">7pm onwards</div>
                            <div className="venue">Gymkhana Grounds</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={ps} alt="" />
                        </div>
                        <div className="details">
                            <div className="date">19th December</div>
                            <div className="time">7pm onwards</div>
                            <div className="venue">Gymkhana Grounds</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={cs} alt="" />
                        </div>
                        <div className="details">
                            <div className="date">20th December</div>
                            <div className="time">7pm onwards</div>
                            <div className="venue">Gymkhana Grounds</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={ss} alt="" />
                        </div>
                        <div className="details">
                            <div className="date">21st December</div>
                            <div className="time">7pm onwards</div>
                            <div className="venue">Gymkhana Grounds</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={au} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card only-mob">
                        <div className="poster">
                            <img src={ns} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="event_card">
                        <div className="poster">
                            <img src={vg} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={pt} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={si} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">LT-PCSA</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={ap} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card only-mob">
                        <div className="poster">
                            <img src={ab} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card only-mob">
                        <div className="poster">
                            <img src={raahgir} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                </div>
                <div className="row no-mob">
                    <div className="event_card">
                        <div className="poster">
                            <img src={ns} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={ab} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                    <div className="event_card">
                        <div className="poster">
                            <img src={raahgir} alt="" />
                        </div>
                        <div className="details">
                            <div className="venue">Convocation Hall</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img src={gradient} className="gradient" alt="" /> */}
            <div
                className="schedule_btn"
                onClick={() => {
                    navigate("/events");
                    window.scrollTo(0, 0);
                }}
            >
                <img src={schedule_btn} className="schedule_bg" alt="" />
                View all Events
            </div>
        </div>
    );
};

export default Page2;
