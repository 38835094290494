import React, { useEffect, useRef } from "react";

import theme_img from "../../assets/images/theme_img.png";
import theme_globe from "../../assets/images/theme_globe.png";
import bus from "../../assets/images/bus_img.png";
import pendulum from "../../assets/images/pendulum.png";
import pg4_gradient from "../../assets/images/pg4_gradient.svg";
import gsap from "gsap/all";

const Page4 = () => {
    const pendulumRef = useRef(null);
    useEffect(() => {
        const pendulumElement = pendulumRef.current;
        gsap.fromTo(
            pendulumElement,
            {
                rotate: 10,
            },
            {
                rotate: -20,
                duration: 3,
                repeat: -1,
                repeatDelay: 3,
                transformOrigin: "50% 0%",
            }
        );
        gsap.to(pendulumElement, {
            rotate: 10,
            duration: 3,
            repeat: -1,
            repeatDelay: 3,
            delay: 3,
            transformOrigin: "50% 0%",
        });
    }, []);
    return (
        <div className="page_4">
            <div className="pg4_gradient">
                <img src={pg4_gradient} alt="" />
            </div>
            <img src={bus} className="bus_img" alt="" />
            <img src={pendulum} ref={pendulumRef} className="pendulum" alt="" />
            <img src={theme_img} className="theme_img" alt="" />
            <img src={theme_globe} className="theme_globe" alt="" />
            <div className="theme_text">
                <div className="theme_small">Theme</div>
                <div className="theme_heading">A Rewired Reality</div>
                <div className="theme_info">
                    Introducing Mood Indigo 2023: A Rewired Reality In a time
                    when the lines between the virtual and the real are
                    constantly blurring, we invite you to embark on a journey as
                    we unravel the matrix of imagination, rewrite the code of
                    storytelling, and rediscover the magic in the mundane. This
                    year, we're exploring uncharted territories where creativity
                    knows no bounds, where reality is just a canvas waiting to
                    be rewired by your imagination.
                </div>
            </div>
        </div>
    );
};

export default Page4;
