import React, { useEffect, useRef } from "react";

import pg3_gradient from "../../assets/images/pg3_gradient.png";
import moon from "../../assets/images/moon.png";
import cloud1 from "../../assets/images/cloud_1.png";
import flower from "../../assets/images/flower.png";
import gsap from "gsap/all";

const Page3 = () => {
    const fl1ref = useRef(null);
    const fl2ref = useRef(null);
    const fl3ref = useRef(null);
    const fl4ref = useRef(null);
    const moonRef = useRef(null);

    useEffect(() => {
        const fl1Element = fl1ref.current;
        const fl2Element = fl2ref.current;
        const fl3Element = fl3ref.current;
        const fl4Element = fl4ref.current;
        const moonElement = moonRef.current;
        gsap.to(fl1Element, {
            rotate: 360,
            duration: 4,
            repeat: -1,
            ease: "none",
        });
        gsap.to(fl2Element, {
            rotate: 360,
            duration: 5,
            repeat: -1,
            ease: "none",
        });
        gsap.to(fl3Element, {
            rotate: 360,
            duration: 3,
            repeat: -1,
            ease: "none",
        });
        gsap.to(fl4Element, {
            rotate: 360,
            duration: 6,
            repeat: -1,
            ease: "none",
        });
        gsap.fromTo(
            moonElement,
            {
                opacity: 1,
            },
            {
                opacity: 0.4,
                repeat: -1,
                repeatDelay: 3,
                duration: 3,
            }
        );
        gsap.fromTo(
            moonElement,
            {
                opacity: 0.4,
            },
            {
                opacity: 1,
                repeat: -1,
                delay: 3,
                duration: 3,
                repeatDelay: 3,
            }
        );
    }, []);

    return (
        <div className="page_3">
            <img src={pg3_gradient} className="pg3_gradient" alt="" />
            <img src={moon} ref={moonRef} className="moon" alt="" />
            <img src={cloud1} className="pg3_cl1" alt="" />
            <img src={cloud1} className="pg3_cl2" alt="" />
            <img src={flower} ref={fl1ref} className="fl1" alt="" />
            <img src={flower} ref={fl2ref} className="fl2" alt="" />
            <img src={flower} ref={fl3ref} className="fl3" alt="" />
            <img src={flower} ref={fl4ref} className="fl4" alt="" />
            <div className="pg3_heading">About Us</div>
            <p className="pg3_desc">
                Mood Indigo is not just the annual cultural fest of IIT Bombay
                but a kaleidoscope of emotions, memories, and boundless
                creativity. This 'Chaar Din ka Sapna' is the place where people
                experience four power packed days of passionate competitions,
                fun games and activities, events of renowned artists,
                electrifying concerts and soothing night life. Over the 53 years
                that Mood Indigo has established its legacy in, it has served
                host to artists like Deepika Padukone, Vidya Balan. Magical
                concerts of Sunidhi Chauhan, Vishal Shekhar have awed the
                audience. All in all, MI is a testament to the indomitable
                spirit of exploration and self-expression, etching lasting
                memories in the hearts of all who partake in its magic.
            </p>
        </div>
    );
};

export default Page3;
