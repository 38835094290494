import React, { useState } from "react";
import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function SocialInfo({ user, setUser, page, setPage, redirect }) {
    const [submitDisabled, setSubmitDisabled] = useState(false);
    let navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        const data = {
            city: Array.isArray(user.city) ? user.city[0] : user.city,
            college: Array.isArray(user.college)
                ? user.college[0]
                : user.college,
            dob: Array.isArray(user.dob) ? user.dob[0] : user.dob,
            email: Array.isArray(user.email) ? user.email[0] : user.email,
            gender: Array.isArray(user.gender) ? user.gender[0] : user.gender,
            googleId: Array.isArray(user.googleId)
                ? user.googleId[0]
                : user.googleId,
            insta: user.insta,
            name: Array.isArray(user.name) ? user.name[0] : user.name,
            password: user.password,
            phone: Array.isArray(user.phone) ? user.phone[0] : user.phone,
            profilePic: Array.isArray(user.profilePic)
                ? user.profilePic[0]
                : user.profilePic,
            referralCode: Array.isArray(user.referral)
                ? user.referral[0]
                : user.referral,
            state: Array.isArray(user.state) ? user.state[0] : user.state,
            stream: Array.isArray(user.stream) ? user.stream[0] : user.stream,
            twitter: Array.isArray(user.twitter)
                ? user.twitter[0]
                : user.twitter,
            yearOfStudy: Array.isArray(user.yearOfStudy)
                ? user.yearOfStudy[0]
                : user.yearOfStudy,
        };
        Axios.post("https://ashish.moodi.org/users/add_user", data, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
        })
            .then((res) => {
                if (res.data.status === "done") {
                    user = jwtDecode(res.data.authtoken).user;
                    localStorage.setItem("miauthtoken", res.data.authtoken);
                    setPage(page + 1);
                    // if (redirect != null) {
                    //     navigate(`/${redirect}`);
                    // } else {
                    //     navigate("/success");
                    // }
                } else {
                    window.alert(res.data.status);
                    setSubmitDisabled(false);
                }
            })
            .catch((err) => console.error(err));
    };
    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    return (
        <div className="step reg-step-3">
            <input
                type="text"
                id="referral"
                name="referral"
                maxLength={9}
                placeholder="Referral Code (Optional)"
                onChange={onChange}
                value={user.referral}
            />
            <input
                type="text"
                name="insta"
                placeholder="Instagram Link (Optional)"
                onChange={onChange}
                value={user.insta}
            />
            <input
                type="text"
                name="twitter"
                placeholder="Twitter Link (Optional)"
                onChange={onChange}
                value={user.twitter}
            />
            <div className="form-footer">
                <button
                    type="submit"
                    className="btn back-btn"
                    onClick={() => {
                        setPage(page - 1);
                    }}
                    disabled={submitDisabled}
                >
                    <img src={leftArrow} alt="" />
                    Back
                </button>
                {submitDisabled ? (
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn next-btn"
                        disabled={submitDisabled}
                    >
                        Submitting....
                        {/* <img src={rightArrow} alt="" /> */}
                    </button>
                ) : (
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn next-btn"
                        disabled={submitDisabled}
                    >
                        Next
                        <img src={rightArrow} alt="" />
                    </button>
                )}
                {/* {submitDisabled ? (
                    <button
                        type="submit"
                        className="btn next-btn"
                        disabled={submitDisabled}
                    >
                        <span className="spinner"></span>
                        Submitting...
                    </button>
                ) : (
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn next-btn"
                        disabled={submitDisabled}
                    >
                        Submit
                    </button>
                )} */}
            </div>
        </div>
    );
}

export default SocialInfo;
