import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import mountains from "../../assets/images/event_pg_mountains.png";
import Navbar from "../Navbar";
import Axios from "axios";
import { jwtDecode } from "jwt-decode";
import pg3_gradient from "../../assets/images/pg3_gradient.png";

const EventPage = () => {
    const { eventId } = useParams();
    const location = useLocation();
    const spotify = useRef(null);
    const [eventDetails, setEventDetails] = useState({});
    const navigate = useNavigate();
    const [likeStatus, setLikeStatus] = useState(false);
    const [user, setUser] = useState(
        localStorage.getItem("miauthtoken")
            ? jwtDecode(localStorage.getItem("miauthtoken")).user
            : null
    );

    const onInterestClick = () => {
        if (localStorage.getItem("miauthtoken")) {
            if (likeStatus) {
                setLikeStatus(false);
                Axios.post("https://sp-admin.moodi.org/remove_interest", {
                    event: eventId,
                    user: user.userId,
                })
                    .then((res) => {
                        if (res.data.status === "done") {
                            setLikeStatus(false);
                            // window.alert("removed");
                        }
                    })
                    .catch((err) => console.error(err));
            } else {
                setLikeStatus(true);
                Axios.post("https://sp-admin.moodi.org/mark_interest", {
                    event: eventId,
                    user: user.userId,
                })
                    .then((res) => {
                        if (res.data.status === "done") {
                            setLikeStatus(true);
                            // window.alert("liked");
                        } else {
                            window.alert(res.data.status);
                        }
                    })
                    .catch((err) => console.error(err));
            }
        } else {
            navigate(`/register?redirect=events/${eventId}`);
        }
    };

    useEffect(() => {
        Axios.get(`https://sp-admin.moodi.org/get_event_info?id=${eventId}`)
            .then((res) => {
                setEventDetails(res.data);
                let spotifyElement = spotify.current;
                spotifyElement.innerHTML = res.data.spotify_embed;
            })
            .catch((err) => console.error(err));
        if (localStorage.getItem("miauthtoken")) {
            Axios.post("https://sp-admin.moodi.org/get_like_status", {
                user: jwtDecode(localStorage.getItem("miauthtoken")).user
                    .userId,
                event: eventId,
            }).then((res) => {
                if (res.data.length > 0) {
                    setLikeStatus(true);
                } else {
                    setLikeStatus(false);
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="event_page">
            <Navbar />
            <img src={pg3_gradient} className="event_pg_gradient" alt="" />
            <img src={mountains} className="event_pg_mountains" alt="" />

            <div className="event_pg_container">
                <div className="poster_container">
                    <img
                        src={`https://sp-admin.moodi.org/get_event_image?img=${eventDetails.poster}`}
                        alt=""
                    />
                    <button
                        className={
                            likeStatus
                                ? "interested_btn active"
                                : "interested_btn"
                        }
                        onClick={onInterestClick}
                    >
                        {likeStatus ? "Interested" : "Interested?"}
                    </button>
                </div>
                <div className="artist_info_container">
                    <p className="artist_title">{eventDetails.event_artist}</p>
                    <p className="artist_info">
                        {eventDetails.artist_description}
                    </p>
                    <div className="spotify_embed" ref={spotify}></div>
                </div>
                <div className="event_info_container">
                    <p className="event_title">{eventDetails.event_name}</p>
                    <p className="event_details">
                        {eventDetails.event_description}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EventPage;
