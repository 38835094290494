import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/HomePage/Home";
import RegistrationPage from "./components/register/RegistrationPage";
import SponsorHome from "./components/Sponsors/SponsorHome";
import EventsHome from "./components/Events/EventsHome";
import EventPage from "./components/Events/EventPage";
import ContactUs from "./components/ContactUs";
import texture from "./assets/images/texture.png";
import PassesHome from "./components/passes/PassesHome";
import Profile from "./components/Profile";
import Schedule from "./components/Schedule";

function App() {
    return (
        <div className="App" style={{ backgroundImage: texture }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/register" element={<RegistrationPage />} />
                    <Route path="/sponsors" element={<SponsorHome />} />
                    <Route path="/events" element={<EventsHome />} />
                    <Route path="/events/:eventId" element={<EventPage />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/passes" element={<PassesHome />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/schedule" element={<Schedule />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
