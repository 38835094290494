import React, { useRef, useState } from "react";
import Axios from "axios";
import google from "../../assets/images/google.svg";
import email from "../../assets/images/email.svg";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function LoginInfo({ user, setUser, page, setPage, redirect }) {
    const nextBtnRef = useRef(null);
    let navigate = useNavigate();
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const onGoogleClick = () => {
        let googlebtn = document.querySelector(".nsm7Bb-HzV7m-LgbsSe");
        googlebtn.click();
    };

    const onEmailClick = () => {
        const nextBtn = nextBtnRef.current;
        nextBtn.click();
    };

    const handleCallbackResponse = (response) => {
        setSubmitDisabled(true);
        var userObject = jwtDecode(response.credential);
        Axios.post(
            "https://ashish.moodi.org/users/getUser",
            {
                email: userObject.email,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.data.exists) {
                    localStorage.setItem("miauthtoken", res.data.authtoken);
                    if (redirect != null) {
                        navigate(`/${redirect}`);
                    } else {
                        navigate("/profile");
                    }
                } else {
                    setUser({
                        ...user,
                        email: userObject.email,
                        googleId: userObject.sub,
                        profilePic: userObject.picture,
                    });
                    setSubmitDisabled(false);
                    let nextBtn = nextBtnRef.current;
                    nextBtn.click();
                }
            })
            .catch((err) => console.error(err));
    };
    return (
        <div className="step">
            <div className="login-btns">
                {submitDisabled ? (
                    <div className="google-login">
                        {/* <img src={google} alt="" /> */}
                        <span className="spinner"></span>
                        <p>Logging in... Please wait</p>
                    </div>
                ) : (
                    <div>
                        {/* -> localhost */}
                        {/* <GoogleOAuthProvider clientId="571154717148-bj2dfukrsaij1rvmi3too5cd3jb82d9j.apps.googleusercontent.com"> */}
                        {/* -> for ujjwal.moodi.org */}
                        {/* <GoogleOAuthProvider clientId="571154717148-hj4krg8d4na2sf2fejm3trc9o9tdpaom.apps.googleusercontent.com"> */}
                        {/* for my.moodi.org */}
                        <GoogleOAuthProvider clientId="571154717148-9bv0ul64uvk3mbiv0kqgk012in8jotat.apps.googleusercontent.com">
                            <GoogleLogin
                                width="20vw"
                                useOneTap={true}
                                onSuccess={handleCallbackResponse}
                                onError={() => {
                                    alert("Login Failed");
                                }}
                            />
                        </GoogleOAuthProvider>
                    </div>
                )}
                <div className="email-login" onClick={onEmailClick}>
                    <img src={email} alt="" />
                    <p>Email Login</p>
                </div>
            </div>
            <div className="form-footer">
                <div className="btn buffer-btn no-disp"></div>
                <button
                    ref={nextBtnRef}
                    style={{ display: "none" }}
                    type="submit"
                    onClick={handleSubmit}
                    className="btn next-btn"
                ></button>
            </div>
        </div>
    );
}

export default LoginInfo;
