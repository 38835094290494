import React, { useEffect, useRef } from "react";

import vinyl from "../../assets/images/vinyl.png";
import pg5_gradient from "../../assets/images/pg5_gradient.png";

import gsap from "gsap/all";

const Page5 = () => {
    const vinylRef = useRef(null);
    useEffect(() => {
        let vinylElement = vinylRef.current;
        gsap.to(vinylElement, {
            rotate: 360,
            duration: 3,
            repeat: -1,
            ease: "none",
        });
    }, []);
    return (
        <div className="page_5">
            <img src={pg5_gradient} className="pg5_gradient" alt="" />

            <img src={vinyl} className="vinyl" ref={vinylRef} alt="" />
            <div className="statistics_text">
                <div className="stats_heading">Statistics</div>
                <p>
                    <strong>Footfall: </strong>
                    <span>1,50,000+</span>
                </p>
                <p>
                    <strong>No of International Artists: </strong>
                    <span>1000+</span>
                </p>
                <p>
                    <strong>No of Events: </strong>
                    <span>240+</span>
                </p>
                <p className="stats_subheading">OVER THE YEARS</p>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>2020: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    Golden Jubilee! Celebrated 50 glorious years
                                    of Mood Indigo
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2019: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    MAGIC! performed for the first time in India
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2016: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    Transform Maharashtra, a social initiative
                                    launched by Hon. CM of Maharashtra Devendra
                                    Fadnavis
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2014: </strong>
                            </td>
                            <td className="oty_right">
                                <span>Sander Van Doorn Concert, EDM Nite</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2012: </strong>
                            </td>
                            <td className="oty_right">
                                <span>Simple Plan Concert</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2011: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    Limca book of world records for most number
                                    of International Artists in any college fest
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2010: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    India's first ever sumo-wrestling match held
                                    at Mood Indigo
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2009: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    Porcupine Tree performed in India for the
                                    first time at Mood Indigo
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>2008: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    First Internation Nite: Emsiferum Concert
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>1981: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    Performance by Asha Bhosle and R D Burman,
                                    stalwarts of Indian Music during the 80s
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>1971: </strong>
                            </td>
                            <td className="oty_right">
                                <span>
                                    First Mood Indigo executed with a budget of
                                    INR 5,000
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Page5;
