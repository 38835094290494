import React, { useState } from "react";
import rightArrow from "../../assets/images/right-arrow.svg";
import Axios from "axios";
import leftArrow from "../../assets/images/left-arrow.svg";
import { jwtDecode } from "jwt-decode";
import FormData from "form-data";
import { useNavigate } from "react-router-dom";

function Details({ page, setPage, redirect }) {
    const navigate = useNavigate();
    const [user, setUser] = useState(
        jwtDecode(localStorage.getItem("miauthtoken")).user
    );
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const onPicUpload = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        const formData = new FormData(e.target);
        console.log(formData);
        Axios.post("https://sp-admin.moodi.org/upload_photo", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            if (res.data.status === "done") {
                if (redirect != null) {
                    navigate(`/${redirect}`);
                } else {
                    navigate("/profile");
                }
            } else {
                window.alert("Please upload your photo and college id first");
                setSubmitDisabled(false);
            }
        });
    };

    return (
        <div className="step reg-step-1">
            <form onSubmit={onPicUpload}>
                <label htmlFor="pic">Upload your photo</label>
                <input
                    type="file"
                    name="pic"
                    id="pic"
                    className="photo_input"
                    required
                />
                <input
                    type="text"
                    name="user"
                    value={user.userId}
                    className="no-disp"
                />
                <div className="form-footer">
                    <button
                        type="submit"
                        disabled={submitDisabled}
                        className="btn next-btn"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Details;
