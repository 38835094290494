import React from "react";
import ag from "../../assets/images/ag.png";
import { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import QRCode from "react-qr-code";
import Navbar from "../Navbar";
import duck from "../../assets/images/duck.gif";

const PassesHome = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState("");
    const [user, setUser] = useState(
        localStorage.getItem("miauthtoken")
            ? jwtDecode(localStorage.getItem("miauthtoken")).user
            : null
    );
    const [currTime, setCurrTime] = useState(new Date());
    // const startTime = new Date(
    //     "Mon Dec 18 2023 09:00:00 GMT+0530 (India Standard Time)"
    // );
    // const startTime = new Date(
    //     "Tue Dec 19 2023 09:00:00 GMT+0530 (India Standard Time)"
    // );
    // const [disabled, setDisabled] = useState(
    //     currTime.getTime() <= startTime.getTime()
    // );
    const [submitDisabled, setSubmitDisabled] = useState(true);

    // setInterval(() => {
    //     setCurrTime(new Date());
    //     // setDisabled(currTime.getTime() <= startTime.getTime());
    // }, 1000);

    // useEffect(() => {
    //     setDisabled(currTime.getTime() <= startTime.getTime());
    //     // eslint-disable-next-line
    // }, [currTime]);

    const bookTicket = () => {
        // if (currTime <= startTime) {
        //     window.alert("Booking will start at 9am");
        // } else {
        if (localStorage.getItem("miauthtoken")) {
            setSubmitDisabled(true);
            Axios.post(
                "https://passadmin.moodi.org/bookTicket",
                {
                    event: 103,
                },
                {
                    headers: {
                        authtoken: localStorage.getItem("miauthtoken"),
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status === "permitted") {
                        setStatus("permitted");
                    } else if (res.data.status === "waiting") {
                        setStatus("waiting");
                    } else {
                        window.alert(res.data.status);
                    }
                })
                .catch((err) => console.error(err));
        } else {
            navigate("/register?redirect=passes");
        }
    };
    // };

    useEffect(() => {
        if (localStorage.getItem("miauthtoken")) {
            Axios.post(
                "https://passadmin.moodi.org/getTicketInfo",
                {
                    event: 103,
                },
                {
                    headers: {
                        authtoken: localStorage.getItem("miauthtoken"),
                    },
                }
            )
                .then((res) => {
                    if (res.data.length === 0) {
                        setSubmitDisabled(false);
                    } else {
                        setStatus(res.data[0].status);
                    }
                })
                .catch((err) => console.error(err));
        } else {
            navigate("/register?redirect=passes");
        }
    }, []);
    return (
        <div className="container-fluid bg-black text-white">
            <Navbar />
            <h2 className="text-center">Today's Humor Event</h2>
            <div className="humor_row">
                <div className="card bg-dark text-white col-sm-3 border-tertiary">
                    <ul>
                        <li>
                            The ticket booking will open at <b>9 AM</b> on the
                            same day of the event
                        </li>
                        <li>
                            Getting a pass here <b>DOES NOT GUARANTEE</b> entry
                            into the event.
                        </li>
                        <li>
                            Entry will be still on{" "}
                            <b>First Come First Serve (FCFS) Basis</b>
                        </li>
                        <li>
                            The pass (if you get) can only be used once for
                            entry into the event. Kindly do not share it with
                            others.
                        </li>
                        <li>
                            If anyone else uses your pass, we will not be
                            responsible for that.
                        </li>
                    </ul>
                </div>
                {status === "permitted" ? (
                    <div className="card bg-dark text-white col-sm-3 border-tertiary">
                        <div className="card-img-top">
                            <QRCode value={user.userId} />
                            <img src={duck} className="duck_img" alt="" />
                        </div>

                        <div className="card-body">
                            <h2 className="card-title">
                                Successfully Registered!
                            </h2>
                            <p className="card-text">
                                Akash Gupta | Convocation Hall | 3.30pm - 5pm
                            </p>

                            {/* <button
                                className="btn btn-primary"
                                onClick={bookTicket}
                            >
                                Download Ticket
                            </button> */}
                        </div>
                    </div>
                ) : status === "waiting" ? (
                    <div className="card bg-dark text-white col-sm-3 border-tertiary">
                        <div className="card-body">
                            <h2 className="card-title">
                                Successfully Registered!
                            </h2>
                            <p className="card-text">
                                You will get your pass soon!
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="card bg-dark text-white col-sm-3 border-tertiary">
                        <img src={ag} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h2 className="card-title">Akash Gupta</h2>
                            <p className="card-text">
                                Convocation Hall <br />
                                3.30pm to 5pm
                            </p>

                            <button
                                className="book_ticket_btn"
                                disabled={submitDisabled}
                                onClick={bookTicket}
                            >
                                Book Ticket
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PassesHome;
