import React, { useEffect, useRef } from "react";
import mi_logo from "../../assets/images/mi_logo.svg";
import cloud1 from "../../assets/images/cloud_1.png";
import human from "../../assets/images/human.png";
import mountains from "../../assets/images/mountains.png";
import { useNavigate } from "react-router-dom";
import gsap from "gsap/all";
import globe from "../../assets/images/globe.png";
import tv from "../../assets/images/tv.png";
import { FaCirclePlay } from "react-icons/fa6";

const Page1 = () => {
    const humanRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const humanElement = humanRef.current;
        gsap.fromTo(
            humanElement,
            {
                yPercent: -10,
                // ease: true,
            },
            {
                yPercent: 10,
                repeat: -1,
                repeatDelay: 2.5,
                duration: 2.5,
                // ease: 1,
            }
        );
        gsap.fromTo(
            humanElement,
            {
                yPercent: 10,
                // ease: true,
            },
            {
                yPercent: -10,
                repeat: -1,
                repeatDelay: 2.5,
                duration: 2.5,
                delay: 2.5,
                // ease: true,
            }
        );
    }, []);

    return (
        <div className="page-1">
            <img src={globe} className="globe" alt="" />
            <img src={mi_logo} className="mi_logo" alt="" />
            <img src={cloud1} className="cloud1" alt="" />
            <img src={cloud1} className="cloud2" alt="" />
            <img src={cloud1} className="cloud3" alt="" />
            <img src={mountains} className="mountains" alt="" />
            <img src={human} ref={humanRef} className="human" alt="" />
            <a
                href="https://www.youtube.com/watch?v=dXFbWM_UvOg"
                className="tv"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FaCirclePlay size={60} />
            </a>
        </div>
    );
};

export default Page1;
