import React from "react";
import Navbar from "./Navbar";

import rocket from "../assets/images/rocket.png";
import contact_cloud from "../assets/images/contact_cloud.png";

import pg3_gradient from "../assets/images/pg3_gradient.png";
import pg4_gradient from "../assets/images/pg4_gradient.svg";
import pg5_gradient from "../assets/images/pg5_gradient.png";
import pg6_gradient from "../assets/images/pg6_gradient.png";

import {
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaXTwitter,
} from "react-icons/fa6";

import bora from "../assets/images/contact/bora.png";
import kaushal from "../assets/images/contact/kaushal.png";
import mi_logo from "../assets/images/mi_logo.svg";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

import krishna from "../assets/images/new_contact/krishna.jpg";
import leena from "../assets/images/new_contact/leena.jpg";
import sharvari from "../assets/images/new_contact/sharvari.jpg";
import drashti from "../assets/images/new_contact/dharsti.jpg";
import arnav from "../assets/images/new_contact/arnav.jpg";
import saarthak from "../assets/images/new_contact/saarthak.jpg";
import saksham from "../assets/images/new_contact/saksham.jpg";
import ritu from "../assets/images/new_contact/ritu.jpg";
import shreyas from "../assets/images/new_contact/shreyas.jpg";
import satyam from "../assets/images/new_contact/satyam.png";
import dhruv from "../assets/images/new_contact/Dhruv.jpg";
import surya from "../assets/images/new_contact/surya.jpg";
import saarthakK from "../assets/images/new_contact/saarthak_k.jpg";
import anushka from "../assets/images/new_contact/anushka.jpg";
import ajeet from "../assets/images/new_contact/ajeet.jpg";
import yash from "../assets/images/new_contact/yash.jpg";
import aryan from "../assets/images/new_contact/aryan.jpg";
import ashish from "../assets/images/new_contact/ashish.jpg";
import dev from "../assets/images/new_contact/dev.jpg";
import kimaya from "../assets/images/new_contact/kimaya.jpg";
import nimbu from "../assets/images/new_contact/nimbu.jpg";
import pratham from "../assets/images/new_contact/pratham.jpg";
import rahul from "../assets/images/new_contact/rahul.jpg";

const ContactUs = () => {
  const location = useLocation();

  return (
    <div className="contact_page">
      <Navbar pathName={location.pathname} />
      <div className="contact_heading">Contact Us</div>
      <img src={rocket} className="rocket" alt="" />
      <img src={contact_cloud} className="contact_cloud" alt="" />
      <img src={pg3_gradient} className="contact_grad_1" alt="" />
      <img src={pg5_gradient} className="contact_grad_2" alt="" />
      <img src={pg6_gradient} className="contact_grad_3" alt="" />
      <img src={pg4_gradient} className="contact_grad_4" alt="" />

      <div className="contact_group">
        <p className="contact_dept_title">Competitions & LYPs</p>
        <div className="contact-card-container">
          <div className="contact-card">
            <img src={krishna} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Krishna Chaudhary</p>
              <p className="cg-pos">Competitions & LYP Head</p>
              <a href="tel:+917066615145" className="cg-phone">
                +91 70666 15145
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/krishna._142/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/krishna-chaudhary-4862a9256/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/7066615145"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={leena} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Leena </p>
              <p className="cg-pos">Competitions & LYP Head</p>
              <a href="tel:+919399359503" className="cg-phone">
                +91 93993 59503
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/leena_jagwani?igsh=MTRjZ3M3d2w1b3Fxbw=="
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/leena-jagwani-3b3907257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9399359503"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">Creatives</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={sharvari} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Sharvari Dhote</p>
              <p className="cg-pos">Ambience Head</p>
              <a href="tel:+918830244398" className="cg-phone">
                +91 88302 44398
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/_sharvari_dhote_?igsh=bXppcnZ5MnpsOTB1&utm_source=qr"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/sharvari-dhote-971765258/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/sharvari_dhote"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8830244398"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={ashish} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Ashish Kushwah</p>
              <p className="cg-pos">Web & Tech Head</p>
              <a href="tel:+918839930495" className="cg-phone">
                +91 88399 30495
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/ashish_k297/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ashish-kushwah-02132b250/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/ashishk297"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8839930495"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <div className="contact-card-container">
          <div className="contact-card">
            <img src={drashti} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Drashti Mehta </p>
              <p className="cg-pos">Design Head</p>
              <a href="tel:+918422916008" className="cg-phone">
                +91 84229 16008
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/_drxshtx__?igsh=ZmM0aDFuYzUzdHlk"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/drashti-mehta-7b733b217?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8422916008"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={kimaya} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Kimaya Itkarkar</p>
              <p className="cg-pos">Design Head</p>
              <a href="tel:+917350250937" className="cg-phone">
                +91 73502 50937
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/jishnuthewalker?igshid=NTc4MTIwNjQ2YQ=="
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/kimaya-itkarkar-0a7828252/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/7350250937"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">Food & Beverages</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={arnav} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Arnav Gupta</p>
              <p className="cg-pos">Food & Beverages Head</p>
              <a href="tel:+919897260594" className="cg-phone">
                +91 98972 60594
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/stfuarnavv"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/arnavgupta26"
              >
                <FaLinkedin color="black" size={35} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9897260594"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="contact_group">
        <p className="contact_dept_title">Horizons</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={saarthak} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Saarthak Laturiya</p>
              <p className="cg-pos">Horizons Head</p>
              <a href="tel:+91 95185 51572" className="cg-phone">
                +91 95185 51572
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/whosaarthak/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.linkedin.com/in/saarthak-laturiya"
              >
                <FaLinkedin color="black" size={35} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9518551572"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={saksham} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Saksham Sureka</p>
              <p className="cg-pos">Horizons Head</p>
              <a href="tel:+919128024644" className="cg-phone">
                +91 91280 24644
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/she_called_me_sage?igsh=MWthdmNlcXppaHF1aQ%3D%3D&utm_source=qr"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/sakshamsureka04?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9128024644"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={nimbu} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Harshit Nimbawat</p>
              <p className="cg-pos">Horizons Head</p>
              <a href="tel:+917073520703" className="cg-phone">
                +91 70735 20703
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/harshitnimbawat._?igsh=MWt2bjU5amZneTZnMQ=="
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/harshit-nimbawat-4a25b7157/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/HarshitNimbawa1?t=6IZBoqB1sGkD0eRi-6Kp4Q&s=09"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/7073520703"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">
          Hospitality, Assistance & Public Relations
        </p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={ritu} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Ritu Raj</p>
              <p className="cg-pos">Hospitality & PR Head</p>
              <a href="tel:+919142246807" className="cg-phone">
                +91 91422 46807
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/the_ritu_raj1304?igsh=eDI5eGQ2bW04amI2&utm_source=qr"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ritu-raj-9b699724b/"
              >
                <FaLinkedin color="black" size={35} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9142246807"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={shreyas} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Shreyas</p>
              <p className="cg-pos">Hospitality & PR Head</p>
              <a href="tel:+918767683564" className="cg-phone">
                +91 87676 83564
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/shreyas.m57?igsh=OW84eDU2Y3hwbzdi"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/shreyas-m-49188824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/Shreyas89779524?t=PMetGfwWfjh5BWjwjueY8w&s=09"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8767683564"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="contact_group">
        <p className="contact_dept_title">Informals, Streets & Workshops</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={surya} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Surya Mahajan</p>
              <p className="cg-pos">Informals Head</p>
              <a href="tel:+917219012348" className="cg-phone">
                +91 72190 12348
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/suryamahajan_?igsh=Y2R5MHZsMTR6OTQz"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/surya-mahajan-8027bb253?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/7219012348"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={dev} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Dev Raj Singh</p>
              <p className="cg-pos">Informals Head</p>
              <a href="tel:+919928542769" className="cg-phone">
                +91 99285 42769
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/dev.raj.26?igsh=Y2tzcDlmazd1Z2Jr"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/dev-raj-singh-chandel"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9928542769"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">Marketing</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={satyam} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Satyam Gupta </p>
              <p className="cg-pos">Marketing Head</p>
              <a href="tel:+918003398039" className="cg-phone">
                +91 80033 98039
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/satyamm2004?igsh=MWIwbmVzbTA3dzVkaQ=="
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/satyam-gupta2003?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8003398039"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={dhruv} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Dhruv Gangrade</p>
              <p className="cg-pos">Marketing Head</p>
              <a href="tel:+919301938525" className="cg-phone">
                +91 93019 38525
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/dhruv_gangrade3/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/dhruv-gangrade-943493265/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a target="_blank" rel="noreferrer" href="https://x.com/dhr3vv">
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9301938525"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={rahul} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Rahul Gupta</p>
              <p className="cg-pos">Marketing Head</p>
              <a href="tel:+918949659389" className="cg-phone">
                +91 89496 59389
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/rahgupta8191/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/rahul-gupta-4a63b2279/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8949659389"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">
          Media, Publicity & Alumni Relations
        </p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={saarthakK} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Sarthak Kulkarni</p>
              <p className="cg-pos">Media Head</p>
              <a href="tel:+919156875399" className="cg-phone">
                +91 91568 75399
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.instagram.com/sarthakkkulkarni"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/sarthakkulkarni29?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.x.com/fkn_parker"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9156875399"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={anushka} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Anushka Khowala</p>
              <p className="cg-pos">Media Head</p>
              <a href="tel:+919830508785" className="cg-phone">
                +91 98305 08785
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/anushka.khowala/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/anushkakhowala/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/_anushka_x"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9830508785"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">Pronites</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={aryan} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Aryan Arora</p>
              <p className="cg-pos">Pronites Head</p>
              <a href="tel:+919717195520" className="cg-phone">
                +91 97171 95520
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/aryanar0ra?igsh=ZHY4MWNtNHYxazRp&utm_source=qr"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/aryan-arora-684a60251?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9717195520"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={yash} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Yash Natiya</p>
              <p className="cg-pos">Pronites Head</p>
              <a href="tel:+919828087300" className="cg-phone">
                +91 98280 87300
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/yashnatiya?igsh=MXNwa2luZmk0ZmRx&utm_source=qr"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/yash-natiya-48b1a1256?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9828087300"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">Operations & Logistics</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={ajeet} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Ajeet Singh Gera</p>
              <p className="cg-pos">Services Head</p>
              <a href="tel:+916266651003" className="cg-phone">
                +91 62666 51003
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/ajeetsingh8758?igsh=ZDE0am5pY3h2enZp"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ajeet-singh-gera-bb1981255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/6266651003"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <div className="contact-card">
            <img src={pratham} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Pratham Singh</p>
              <p className="cg-pos">Services Head</p>
              <a href="tel:+917678185743" className="cg-phone">
                +91 76781 85743
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/am.praths/"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/pratham-singh-27b4b2253/"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/7678185743"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_group">
        <p className="contact_dept_title">Overall Coordinatiors</p>

        <div className="contact-card-container">
          <div className="contact-card">
            <img src={bora} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Arjun Bora</p>
              <p className="cg-pos">Overall Coordinators</p>
              <a href="tel:+916395425981" className="cg-phone">
                +91 63954 25981
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/arjun-bora-46a38723b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/arjun-bora-46a38723b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/Boraaabol?t=c_pD4u5lRgKokoJ3jLYmJw&s=09"
              >
                <FaXTwitter color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8983280146"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
          <img src={mi_logo} className="mi_logo_contact" alt="" />
          <div className="contact-card">
            <img src={kaushal} className="cg-img" alt="" />
            <div className="cg-details">
              <p className="cg-name">Kaushal Khunteta</p>
              <p className="cg-pos">Overall Coordinators</p>
              <a href="tel:+919351167811" className="cg-phone">
                +91 93511 67811
              </a>
            </div>
            <div className="contact-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/kaushalkhunteta"
              >
                <FaInstagram color="black" size={35} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9351167811"
              >
                <FaWhatsapp color="black" size={35} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
