import React from "react";
import Navbar from "./Navbar";

import schedule from "../assets/images/sch_d4.png";

const Schedule = () => {
    return (
        <div className="schedule_pg">
            <Navbar />
            <p className="schedule_title">Today's Schedule</p>
            <div className="sch_container">
                <img src={schedule} alt="" />
            </div>
            <p className="schedule_scroll">Scroll image to view -&gt;</p>
        </div>
    );
};

export default Schedule;
