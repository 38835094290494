import React, { useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./Events.css";
import EventCatergory from "./EventCatergory";
import imf_bg from "../../assets/images/imf_bg.mp4";
import concerts_bg from "../../assets/images/concerts_bg.mp4";
import pro_bg from "../../assets/images/pro_bg.mp4";
import humor_bg from "../../assets/images/humor.mp4";
import world_bg from "../../assets/images/world_fiesta.mp4";
import workshop_bg from "../../assets/images/workshops.mp4";
import theatre_bg from "../../assets/images/theatre_bg.jpg";
import games_bg from "../../assets/images/games_bg.png";

const EventsHome = () => {
    const concertsRef = useRef(null);
    const proshowsRef = useRef(null);
    const humorRef = useRef(null);
    const imfRef = useRef(null);
    const worldRef = useRef(null);
    const theatreRef = useRef(null);
    const workshopRef = useRef(null);
    const gamesRef = useRef(null);
    const eventsNavRef = useRef(null);

    window.onscroll = function () {
        var allElements = document.querySelectorAll(".genre_card");
        var container = document.getElementsByClassName("genre_viewport");
        allElements.forEach((element) => {
            if (
                element.getBoundingClientRect().top >=
                    container[0].getBoundingClientRect().top &&
                element.getBoundingClientRect().bottom <
                    container[0].getBoundingClientRect().bottom &&
                element.getBoundingClientRect().bottom >
                    container[0].getBoundingClientRect().top &&
                element.getBoundingClientRect().top <=
                    container[0].getBoundingClientRect().bottom
            ) {
                if (!element.classList.contains("gen_active")) {
                    element.classList.add("gen_active");
                    let id = element.id;

                    var newLinks = document.getElementsByClassName(
                        `gen_link_${id}`
                    );
                    for (let i = 0; i < newLinks.length; i++) {
                        if (
                            !newLinks[i].classList.contains("gen_link_active")
                        ) {
                            newLinks[i].classList.add("gen_link_active");
                        }
                    }
                }
            } else {
                if (element.classList.contains("gen_active")) {
                    element.classList.remove("gen_active");
                    var activeLinks = document.getElementsByClassName(
                        `gen_link_${element.id}`
                    );
                    for (let i = 0; i < activeLinks.length; i++) {
                        if (
                            activeLinks[i].classList.contains("gen_link_active")
                        ) {
                            activeLinks[i].classList.remove("gen_link_active");
                        }
                    }
                }
            }
        });
    };

    useEffect(() => {
        let stickyElem = eventsNavRef.current;
        let currStickyPos =
            stickyElem.getBoundingClientRect().top + window.pageYOffset;

        window.onscroll = function () {
            if (window.pageYOffset > currStickyPos) {
                stickyElem.style.position = "fixed";
                stickyElem.style.top = 0;
            } else {
                stickyElem.style.position = "relative";
                stickyElem.style.top = "initial";
            }
        };
    }, []);

    return (
        <div className="events_page">
            <Navbar />
            <div className="events_navbar" ref={eventsNavRef}>
                Genres:
                <div
                    onClick={() => {
                        concertsRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Concerts
                </div>
                <div
                    onClick={() => {
                        proshowsRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Proshows
                </div>
                <div
                    onClick={() => {
                        humorRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Humor
                </div>
                <div
                    onClick={() => {
                        imfRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    IMF
                </div>
                <div
                    onClick={() => {
                        worldRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    World Fiesta
                </div>
                <div
                    onClick={() => {
                        theatreRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Theatre Fest
                </div>
                <div
                    onClick={() => {
                        workshopRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Workshops
                </div>
                <div
                    onClick={() => {
                        gamesRef.current.scrollIntoView({
                            behavior: "smooth",
                        });
                    }}
                >
                    Informal Games
                </div>
            </div>

            <div ref={concertsRef}>
                <EventCatergory
                    bg={concerts_bg}
                    title={"Concerts"}
                    type={"concerts"}
                />
            </div>
            <div ref={proshowsRef}>
                <EventCatergory
                    title={"Proshows"}
                    type={"proshows"}
                    bg={pro_bg}
                />
            </div>
            <div ref={humorRef}>
                <EventCatergory
                    title={"Humor Fest"}
                    type={"humor"}
                    bg={humor_bg}
                />
            </div>
            <div ref={imfRef}>
                <EventCatergory
                    title={"International Music Festival"}
                    type={"imf"}
                    bg={imf_bg}
                />
            </div>
            <div ref={worldRef}>
                <EventCatergory
                    title={"World Fiesta"}
                    type={"world"}
                    bg={world_bg}
                />
            </div>
            <div ref={theatreRef}>
                <EventCatergory
                    title={"Theatre Fest"}
                    type={"theatre"}
                    bg={theatre_bg}
                    bg_type={"image"}
                />
            </div>
            <div ref={workshopRef}>
                <EventCatergory
                    title={"Workshops"}
                    type={"workshops"}
                    bg={workshop_bg}
                />
            </div>
            <div ref={gamesRef}>
                <EventCatergory
                    title={"Informal Games"}
                    type={"informal_game"}
                    bg={games_bg}
                    bg_type={"image"}
                />
            </div>
            <Footer />
        </div>
    );
};

export default EventsHome;
