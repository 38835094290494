import React, { useEffect, useRef, useState } from "react";
import "./Registration.css";
import "../../assets/fonts/pp-gothic/stylesheet.css";
import "../../assets/fonts/typo-graphica/stylesheet.css";

import PersonalInfo from "./PersonalInfo";
import CollegeInfo from "./CollegeInfo";
import SocialInfo from "./SocialInfo";
import LoginInfo from "./LoginInfo";
import EmailLogin from "./EmailLogin";
import RegistrationTemplate from "./RegistrationTemplate";
import Details from "./Details";
import { useNavigate } from "react-router-dom";

function RegistrationPage() {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");

    const nextBtnRef = useRef(null);
    const [page, setPage] = useState(0);

    const [user, setUser] = useState({
        email: "",
        password: "",
        otp: "",
        googleId: "",
        profilePic: "",
        name: "",
        dob: "",
        phone: "",
        gender: "",
        state: "",
        city: "",
        college: "",
        stream: "",
        yearOfStudy: "",
        referral: "",
        insta: "",
        twitter: "",
    });

    const FormTitles = [
        "",
        "",
        "About You",
        "About Your College",
        "Social Media",
        "Interests",
    ];

    const PageColors = ["indigo", "indigo", "pink", "indigo", "green", "pink"];

    const PageDisplay = () => {
        if (page === 0) {
            return (
                <LoginInfo
                    user={user}
                    setUser={setUser}
                    page={page}
                    setPage={setPage}
                    redirect={redirect}
                />
            );
        } else if (page === 1) {
            return (
                <EmailLogin
                    nextBtnRef={nextBtnRef}
                    user={user}
                    setUser={setUser}
                    page={page}
                    setPage={setPage}
                    redirect={redirect}
                />
            );
        } else if (page === 2) {
            return (
                <PersonalInfo
                    user={user}
                    setUser={setUser}
                    page={page}
                    setPage={setPage}
                    redirect={redirect}
                />
            );
        } else if (page === 3) {
            return (
                <CollegeInfo
                    user={user}
                    setUser={setUser}
                    page={page}
                    setPage={setPage}
                    redirect={redirect}
                />
            );
        } else if (page === 4) {
            return (
                <SocialInfo
                    user={user}
                    setUser={setUser}
                    page={page}
                    setPage={setPage}
                    redirect={redirect}
                />
            );
            // }
        } else {
            return (
                <Details redirect={redirect} page={page} setPage={setPage} />
            );
        }
    };

    useEffect(() => {
        if (localStorage.getItem("miauthtoken")) {
            navigate("/profile");
        }
    }, []);

    return (
        <div className={`reg-screen bg-${PageColors[page]}`}>
            <RegistrationTemplate />
            <div className="reg-form">
                <div
                    className="form-title"
                    style={
                        page === 0
                            ? { display: "none" }
                            : page === 1
                            ? { display: "none" }
                            : { display: "flex" }
                    }
                >
                    <p>{FormTitles[page]}</p>
                </div>
                <div className="form-body">{PageDisplay()}</div>
            </div>
        </div>
    );
}

export default RegistrationPage;
