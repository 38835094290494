import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";
import texture from "../../assets/images/texture.png";

gsap.registerPlugin(ScrollTrigger);

const EventCatergory = ({ type, title, bg, bg_type }) => {
    const [eventRows, setEventRows] = useState([]);

    const containerRef = useRef(null);
    const textRef = useRef(null);
    const eventsContainerRef = useRef(null);
    const eventCatergoryRef = useRef(null);
    const pinRef = useRef(null);

    useEffect(() => {
        Axios.get(`https://sp-admin.moodi.org/getEvents?type=${type}`).then(
            (res) => {
                setEventRows(
                    [...Array(Math.ceil(res.data.length / 4))].map((row, idx) =>
                        res.data.slice(idx * 4, idx * 4 + 4)
                    )
                );
            }
        );
        let ctx = gsap.context(() => {
            let text = textRef.current;
            let eventsContainer = eventsContainerRef.current;
            let eventCatergory = eventCatergoryRef.current;
            let pinDiv = pinRef.current;

            gsap.to(text, {
                yPercent: -40,
                scale: 0,
                opacity: 0,
                ease: "none",
                scrollTrigger: {
                    trigger: text,
                    start: "top top",
                    end: "20% top",
                    pin: pinDiv,
                    scrub: 1,
                    // markers: true,
                    pinSpacer: false,
                },
            });
            gsap.to(eventsContainer, {
                yPercent: -100,
                scrollTrigger: {
                    trigger: eventCatergory,
                    start: "top 80%",
                    end: "bottom bottom",
                    pin: pinDiv,
                    scrub: 1,
                    // markers: true,
                    pinSpacer: false,
                },
            });
            // gsap.to("event_catergory_page", {
            //     scale: 0,
            //     scrollTrigger: {
            //         trigger: ".event_catergory_page",
            //         start: "top 80%",
            //         end: "bottom bottom",
            //         pin: ".pin",
            //         scrub: 1,
            //         markers: true,
            //         pinSpacer: false,
            //     },
            // });
        });
        return () => ctx.revert(); // <-- CLEANUP!
        // eslint-disable-next-line
    }, []);

    return (
        <div className="event_cat_container">
            <div className="event_cat_viewPort" ref={pinRef}>
                {bg_type === "image" ? (
                    <img src={bg} className="event_pg_bg" alt="" />
                ) : (
                    <video
                        src={bg}
                        loop
                        autoPlay="autoplay"
                        className="event_pg_bg"
                        alt=""
                        ref={containerRef}
                        muted
                        controls={false}
                        playsInline
                        defaultMuted
                    />
                )}
                <div className="event_cat_fp_title" ref={textRef}>
                    {title}
                </div>
            </div>

            <div
                className="event_catergory_page"
                ref={eventCatergoryRef}
                style={{ backgroundImage: texture }}
            >
                <div className="events_conatiner" ref={eventsContainerRef}>
                    {eventRows.map((row, idx) => {
                        return (
                            <div className="events_row" key={idx}>
                                {row.map((element, idx) => {
                                    return (
                                        <Link
                                            to={`/events/${element.eventId}`}
                                            className="event_pg_card"
                                            key={idx}
                                        >
                                            <div className="event_img">
                                                <img
                                                    src={`https://sp-admin.moodi.org/get_event_image?img=${element.poster}`}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="event_name">
                                                {element.event_name}
                                            </div>
                                            <div className="event_artist">
                                                {element.event_artist}
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EventCatergory;
